import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../../components/Layout';
import Cover from '../../../components/Elements/Cover';
import PageSection from '../../../components/Elements/PageSection';

import LinkButton from '../../../components/Elements/LinkButton';
import Row, { Col } from '../../../components/Elements/Grid';
import Image from '../../../components/Elements/Image';
import SignUp from '../../../components/Parts/SignUp';
import withTranslations from '../../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('overOnsKantoor');

  return (
    <Layout className="office">
      <Seo title={t('seoTitle')} />
      <Cover image="avocado" nowrap>
        <Image
          image="headerKantoor"
          className="cover-photo"
          style={{ position: 'fixed' }}
          imgStyle={{ objectPosition: 'center bottom' }}
        />
        <div className="wrapped">
          <PageSection center>
            <h1>{t('intro.title')}</h1>
            <p>{t('intro.description.p1')}</p>
            <p>{t('intro.description.p2')}</p>
            <p>{t('intro.description.p3')}</p>
            <p>{t('intro.description.p4')}</p>
            <p>
              <LinkButton to="/contact">{t('common:getReady')}</LinkButton>
            </p>
          </PageSection>
          <PageSection grid className="photos">
            <Row>
              <Col filled>
                <Image image="pageKantoorPand1" />
              </Col>
              <Col filled>
                <Image image="pageKantoorPand2" />
              </Col>
              <Col filled>
                <Image image="pageKantoorPand3" />
              </Col>
              <Col filled>
                <Image image="pageKantoorPand4" />
              </Col>
              <Col filled>
                <Image image="pageKantoorPand5" />
              </Col>
              <Col filled>
                <Image image="pageKantoorPand6" />
              </Col>
            </Row>
          </PageSection>
          <SignUp image="footerOffice" />
        </div>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
